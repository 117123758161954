"use client";

import logo from "../images/kwala.jpeg";
import raydium from "../images/raydium.png";
import solanaLogo from "../images/solana.png";
import forest from "../images/images.jpg";

export default function Hero() {
  return (
    <section
      className=" grid w-full md:grid-cols-2 text-white md:w-full md:h-screen min-h-screen"
      id="home"
    >
      <div className="flex items-center justify-center md:justify-start bg-[#284220] md:h-screen w-[100vw] md:w-full">
        <div className="max-w-[1000px] min-w-[600px] ">
          {/* Center the image vertically */}
          <img
            src={logo}
            alt="Kwala"
            className="w-full h-auto max-h-[300px] max-w-[400px] md:max-w-[700px]  md:max-h-[1200px] mx-auto"
          />
        </div>
      </div>
      <div className=" bg-[rgba(21,137,116,1)] px-4 py-8 flex flex-col justify-center md:h-full w-[100vw] md:w-full">
        <div className="flex flex-col justify-between h-full md:h-[450px] lg:h-[530px]">
          <span className="">
            <h1 className="text-2xl sm:text-4xl lg:text-6xl font-['Gluten'] text-center">
              Join the Kwala Community
            </h1>
            <p className="lg:mt-8 md:text-2xl lg:text-4xl font-light md:mt-4 w-[90vw] md:w-full text-lg sm:text-3xl mx-auto">
              Become a caretaker of the environment. Earn merit based rewards.
            </p>
          </span>
          <span className="w-[100vw] md:w-full">
            <h1 className="lg:text-6xl md:text-5xl text-2xl sm:text-5xl font-['Gluten'] items-center w-full text-center">
              Exchanges
            </h1>
            <div className="flex justify-center">
              <div>

             
              <div className="w-[120px] sm:w-[160px] md:w-[230px] text-md sm:text-2xl md:text-3xl md:mt-3 mt-1 border-2 border-white flex justify-evenly items-center rounded-xl text-center">
                <button
                  className="ml-1"
                  onClick={() => {
                    window.open("https://raydium.io/swap/?inputCurrency=sol&outputCurrency=9h4Y8t3hybRQH6YvufF7y2V6SyVMwKxLPBkJM4xTwuQ3&inputAmount=0.5&outputAmount=18034074.929196&fixed=in", "_blank");
                  }}
                >
                  Raydium
                </button>
                <img
                  src={raydium}
                  alt="Kwala"
                  className="mt-1 mb-1 md:w-10 w-6"
                />
              </div>
              <p>click the exchange to buy</p>
              </div>
            </div>
          </span>
          <span>
            <div className="flex items-center justify-center w-[90vw] md:w-full">
              <div className="flex items-center">
                <img
                  src={solanaLogo}
                  alt="Kwala"
                  className="lg:w-10 mr-4 w-7"
                />
                <div className="lg:text-[26pt] sm:text-2xl border-b-2 cursor-pointer" onClick={()=>{window.open("https://solscan.io/token/9h4Y8t3hybRQH6YvufF7y2V6SyVMwKxLPBkJM4xTwuQ3", "_blank")}}>
                  Check $Kwala on Solscan
                </div>
              </div>
            </div>
            <div className="lg:text-[12.2pt] sm:text-lg md:text-sm text-[8pt] flex md:items-center justify-center mt-2 w-full">
              CA: 9h4Y8t3hybRQH6YvufF7y2V6SyVMwKxLPBkJM4xTwuQ3
            </div>
          </span>
        </div>
      </div>
    </section>
  );
}
