"use client";
import React, { useState } from "react";
import logo from "../images/logo-removebg-preview.png";

import menu from "../images/menu.png";

const NavBar = () => {
  const [openMenu, setOpenMenu] = useState(false);

  function Menu() {
    let list = document.querySelector("ul");
    setOpenMenu(!openMenu);
    if (openMenu) {
      list.classList.remove("opacity-95");
    } else {
      list.classList.add("opacity-95");
    }
  }

  return (
    <span className="flex items-center justify-center z-[20] mb-8 md:mb-20">
      <div className="z-[20] rounded-2xl md:rounded-none bg-[#43814b] h-16 w-[95vw] md:w-full lg:h-36 sm:h-28 flex justify-between md:text-md lg:text-xl top-6 fixed md:top-0 md:opacity-100 opacity-80">
        <div className="float-right flex items-center justify-start ml-6 min-w-[225px] lg:min-w-[325px] cursor-pointer h-full">
          <img
            className="w-10 md:w-24 top-2 lg:w-32 lg:h-30 md:float-right float-left"
            src={logo}
            alt="Kwala"
          />
          <div className="text-white text-2xl sm:text-4xl font-['Faster_One'] ml-4 lg:text-5xl">
            Kwala
          </div>
        </div>
        <ul className="max-w-[900px] float-right md:flex md:justify-evenly md:items-center md:w-[60vw] md:h-full font-['Gorditas'] text-white md:static absolute bg-[#43814b] top-14 sm:top-20 md:opacity-100 opacity-0 md:p-0 pl-5 w-full">
          <button
            onClick={() => {
              window.open("https://raydium.io/swap/?inputCurrency=sol&outputCurrency=9h4Y8t3hybRQH6YvufF7y2V6SyVMwKxLPBkJM4xTwuQ3&inputAmount=0.5&outputAmount=18034074.929196&fixed=in", "_blank");
            }}
            className="hidden md:block bg-[#1a4419] rounded-lg text-[#e2e25d] px-3 py-3 lg:px-6 lg:py-5 transition-colors duration-500 ease-in-out transition-bg hover:bg-[#000000] hover:text-[#fdfd66]"
          >
            Buy Now
          </button>
          <NavbarItem name="Home" />
          <NavbarItem name="About" />
          <NavbarItem name="Roadmap" />
          <NavbarItem name="How to Buy" />
        </ul>
        <span
          className="text-4xl md:hidden cursor-pointer flex items-center mr-6 mt-1"
          onClick={Menu}
        >
          {openMenu ? (
            <ion-icon name="close-outline" />
          ) : (
            <ion-icon name="menu-outline" />
          )}
        </span>
      </div>
      <div className="fixed flex items-center justify-center bottom-2 left-0 right-0 md:hidden z-[20]">
        <button
          onClick={() => {
            window.open("https://raydium.io/swap/?inputCurrency=sol&outputCurrency=9h4Y8t3hybRQH6YvufF7y2V6SyVMwKxLPBkJM4xTwuQ3&inputAmount=0.5&outputAmount=18034074.929196&fixed=in", "_blank");
          }}
          className="font-bold rounded-lg w-full sm:w-[90vw] h-18 text-3xl py-5 bg-gradient-to-r from-[#18251b] to-[#1c531c] text-[#fac34b] opacity-100"
        >
          Buy Now
        </button>
      </div>
    </span>
  );
};

const NavbarItem = ({ name }) => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <li
      className="whitespace-nowrap md:w-auto cursor-pointer transition-transform duration-500 ease-in-out hover:translate-y-[-10px] hover:border-b-2 hover:border-yellow-500 mx-1 my-6 md:mx-0 md:my-0 text-[#ffffff] shadow-lg text-shadow-yellow-500/50"
      onClick={() => scrollToSection(name.toLowerCase())}
    >
      {name}
    </li>
  );
};

export default NavBar;
