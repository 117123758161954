
import './App.css';
import Navbar from "./components/Navbar";
import Hero from "./screens/Hero";
import About from "./screens/About";
import Roadmap from "./screens/Roadmap";
import HowToBuy from "./screens/HowToBuy";

import Footer from "./screens/Footer";

function App() {
  return (
    <main className=" flex justify-center items-center h-full
    bg-[#17232c]
    ">
      <link
        href="https://fonts.googleapis.com/css2?family=Faster+One&display=swap"
        rel="stylesheet"
      />

      <link
        href="https://fonts.googleapis.com/css2?family=Gorditas&display=swap"
        rel="stylesheet"
      />

      <link
        href="https://fonts.googleapis.com/css2?family=Gluten:wght@400;700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Goldman&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Delius+Unicase&display=swap"
        rel="stylesheet"
      />
      <script
        type="module"
        src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js"
      />
      <script
        noModule
        src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.js"
      />
      <div className="max-w-[1400px] ">
        <Navbar />
        <Hero />

        <About />
        <Roadmap />
        <HowToBuy />
        <Footer />
      </div>
    </main>
  );
}

export default App;
