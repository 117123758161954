export default function Roadmap() {
  return (
    <section className="z-[0] text-white grid h-[100vh] w-full mt-4 min-h-[777px] mb-8 md:mb-0" id="roadmap">
      <span className=" bg-[#41AC6C] text-center font-['Goldman'] justify-center items-center flex h-[55vh] md:w-full w-[100vw]">
        <div className=" justify-center">
          <h1 className="text-4xl sm:text-5xl sm:mb-6 md:text-6xl lg:text-7xl mt-2">
            Road Map Phase 1-3
          </h1>
          <div className="flex flex-col sm:flex-row justify-evenly ">
            <RoadmapItem
              header="Phase 1"
              subscript="Build a solid community through social media"
            />
            <RoadmapItem
              header="Phase 2"
              subscript="List on several exchanges"
            />
            <RoadmapItem
              header="Phase 3"
              subscript="Create separate platform to host nominees from the community to receive rewards"
            />
          </div>
        </div>
      </span>
      <span className=" bg-[#152B4D]  py-4 text-center font-['Goldman'] justify-center items-center flex  h-[60vh]  md:w-full w-[100vw]">
        <div className="flex flex-col justify-between h-[50vh] w-full">
          <h1 className="md:mt-4 text-4xl sm:text-5xl sm:mb-6 md:text-6xl lg:text-7xl">
            Tokenomics
          </h1>
          <div className="md:flex items-center flex md:flex-row flex-col md:w-full ">
            <TokenItems header="1B" text="Supply" header2="Team" text2="5%" />
            <TokenItems header="R&D" text="2%" header2="Hype" text2="1%" />
          </div>
          <div className=" text-xs ml-6 mb-3  md:text-lg lg:text-2xl lg:mt-4">
            <h2 className="  text-left font-bold">Contract Address:</h2>
            <p className="text-left ">
              9h4Y8t3hybRQH6YvufF7y2V6SyVMwKxLPBkJM4xTwuQ3
            </p>
          </div>
        </div>
      </span>
    </section>
  );
}

const RoadmapItem = ({ header, subscript }) => {
  return (
    <div className="flex flex-col items-center justify-start sm:h-[13vh] sm:mt-8 sm:mb-6 m-4 ">
      <h1 className="text-3xl sm:text-3xl min-w-[126px]">{header}</h1>
      <p className="text-xl mt-2 sm:text-xl max-w-[500px] ">{subscript}</p>
    </div>
  );
};

const TokenItems = ({ header, text, header2, text2 }) => {
  return (
    <div className="flex justify-evenly md:w-1/2 m-4 md:mt-0">
      <div className="md:h-[15vh] lg:h-[20vh] bg-[#6482AE] w-40 md:mr-[1.5vw] lg:mr-[2vw] h-22 lg:w-52 rounded-lg mr-8 max-h-[200px]">
        <div>
          <h1 className="text-3xl md:text-4xl mt-4 lg:text-7xl">{header}</h1>
          <p className="font-['Delius_Unicase'] text-lg md:text-2xl lg:text-3xl md:mt-3">
            {text}
          </p>
        </div>
      </div>
      <div className="min-h-[110px] bg-[#6482AE] w-40 rounded-lg md:ml-3 lg:w-52">
        <div>
          <h1 className="text-3xl md:text-4xl mt-4 lg:text-7xl lg:mt-5">
            {header2}
          </h1>
          <p className="font-['Delius_Unicase'] text-lg md:text-2xl lg:text-3xl md:mt-2">
            {text2}
          </p>
        </div>
      </div>
    </div>
  );
};
