"use client";

import mountain from "../images/mountain.png";
import telegram from "../images/telegram2.png";

export default function Footer() {
  return (
    <section className="sm:ml-0 w-[100vw] md:w-full bg-black h-48  text-white md:h-20">
      <div className=" flex items-center justify-between w-[90vw] xs:w-[100vw] lg:w-full">
        <div className="flex items-center">
          {/* Assuming Image is a custom component */}
          <img src={mountain} className="mt-4 ml-4" alt="mountain"></img>
          <div>
            <p className="font-Goldman text-xs sm:text-xl ml-4 md:text-2xl">
              Contact us for questions or suggestions
            </p>
          </div>
        </div>
        <div className="flex items-center space-x-3 md:space-x-4 lg:space-x-10 text-4xl md:text-5xl md:mr-4 lg:mr-4 lg:text-7xl">
          <a
            className=" cursor-pointer"
            onClick={() => {
              window.open(
                "https://t.me/+SSr9zb4SsMoxNjJh",
                "_blank"
              );
            }}
          >
            <img src={telegram} className=" w-8 sm:w-10 md:w-12 md:min-w-10 lg:w-16"></img>
          </a>
          <a
            className=" cursor-pointer"
            onClick={() => {
              window.open("https://www.instagram.com/kwalatoken/", "_blank");
            }}
          >
            <ion-icon name="logo-instagram"></ion-icon>
          </a>
          <a
            className=" cursor-pointer"
            onClick={() => {
              window.open("https://www.tiktok.com/@kwalatoken", "_blank");
            }}
          >
            <ion-icon name="logo-tiktok"></ion-icon>
          </a>
          <a
            className=" cursor-pointer"
            onClick={() => {
              window.open("https://twitter.com/KwalaToken", "_blank");
            }}
          >
            <ion-icon name="logo-twitter"></ion-icon>
          </a>
        </div>
      </div>
    </section>
  );
}
