import kwala from "../gif/kwala.gif";

import forest from "../images/forest.png";
import plant from "../images/plant.png";
import bitcoin from "../gif/bitcoin.gif";
import king from "../images/kingkwala.png";

export default function About() {
  return (
    <section
      className="min-h-[730px] text-white h-[107vh] grid xs:w-full f-full"
      id="about"
    >
      <span className="z-[0] relative h-[45vh] w-full ">
        <img
          src={kwala}
          alt="Kwala GIF"
          className="z-[0] w-[100vw] h-full object-cover"
        />
        <div className="z-[0] absolute inset-0 bg-[#41AC6C]  bg-opacity-85 flex justify-center w-[100vw] right-0 md:w-full">
          <div className="ml-8">
            <h1 className="text-2xl sm:text-4xl lg:text-6xl font-['Gluten'] mt-8 mx-auto">
              Empowering Communities, Rewarding Excellence
            </h1>
            <p className="text-xl sm:text-2xl mt-8 mx-auto">
              Kwala is community based project that aims to promote
              environmental and social responsibilities. Our goal is to provide
              a platform for individuals and groups to be recognized for their
              outstanding contributions. Join us in making a positive impact on
              the world!
            </p>
          </div>
        </div>
      </span>
      <span className=" h-[65vh] sm:w-full bg-[#158974] text-center w-[100vw]" >
        <div>
          <h1 className="text-5xl sm:text-7xl  font-['Gluten'] mt-8">
            Our Mission
          </h1>
          <div className="flex justify-evenly mt-0 ">
            <MissionItems subscript="Eco-friendly" src={plant} />
            <MissionItems subscript="Sustainable" src={forest} />
            <MissionItems subscript="Rewards" src={bitcoin} />
          </div>
          <div className="flex justify-center items-center">
            <img src={king} className="w-16" alt="king kwala" />
            <h3 className="text-2xl sm:text-4xl  w-auto text-center">
              Community is our focus
            </h3>
          </div>
        </div>
      </span>
    </section>
  );
}

const MissionItems = ({ src, subscript }) => {
  return (
    <div className="flex flex-col items-center h-[40vh] justify-center">
      <img
        src={src}
        alt="Bitcoin"
        className="w-[25vw] h-[20vh]  md:w-[30vw] md:h-[25vh] rounded-lg max-w-[330px]"
      />
      <span className="text-large sm:text-2xl md:text-3xl mt-2 font-['Gorditas']">
        {subscript}
      </span>
    </div>
  );
};
