"use client";
import React, { useEffect, useState } from "react";
import forest from "../gif/forest.gif";

import plus from "../images/plus.png";
import closeIcon from "../images/close.png";
import logo from "../images/kwala.jpeg";

// AccordionItem component
const AccordionItem = ({ title, content, onToggle }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    onToggle(!isOpen);
  };

  return (
    <div className="w-60 sm:w-80 border-b border-gray-300 rounded mb-2 z-20 cursor md:w-full max-w-[642px] ">
      <button
        className="w-full flex items-center justify-between text-left p-2 focus:outline-none z-10"
        onClick={handleToggle}
      >
        <h2 className="text-lg sm:text-2xl md:text-3xl lg:text-4xl">
          {title}
        </h2>
        <img
          src={isOpen ? closeIcon : plus}
          alt={isOpen ? "Close Icon" : "Plus Icon"}
          width={isOpen ? 20 : 24}
          height={isOpen ? 20 : 24}
          
        />
      </button>
      {isOpen && (
        <div className="p-4 md:text-xl lg:text-2xl z-[100]">
          {content.map((line, index) => (
            <p key={index}>
              {index + 1}. {line}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default function HowToBuy() {
  const [isAnyAccordionOpen, setIsAnyAccordionOpen] = useState(false);

  return (
    <section
      id="how to buy"
      className="text-white w-[100vw] h-[100vh] max-h-[950px]  grid md:w-full font-['Gluten'] z-[-1]  bg-[#158974] min-h-[800px] md:min-h-[600px] bg-opacity-85 md:h-[70vh] md:max-h-[800px]"
    >
      <span className="z-[0] relative w-full md:h-[50vh] ">
        <img
          src={forest}
          alt="Kwala GIF"
          className="md:max-h-[800px] z-[0] w-full h-full object-cover md:h-[70vh] "
        />
        <div
          className={`md:max-h-[800px] md:h-[70vh] absolute inset-0 bg-[#158974] bg-opacity-85 flex justify-center ${
            isAnyAccordionOpen ? "b-32" : ""
          }`}
        >
          <div className="">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl mt-8 mx-auto text-center mb-2">
              How To Buy
            </h1>
            {/* Accordion */}
            <div className="md:grid md:grid-cols-2 md:gap-0 md:h-[50vh] z-[1] sm:mt-8">
              <div className="w-full flex">
                <div className="mx-auto md:ml-8 md:mt-8 md:w-[60vw] z-[1] ">
                  <AccordionItem
                    title="1. Create a wallet"
                    content={[
                      "Go to this link: https://phantom.app/download",
                      "Select your browser",
                      "Add it to your browser",
                      "Create a new wallet",
                    ]}
                    onToggle={(isOpen) => setIsAnyAccordionOpen(isOpen)}
                  />
                  <AccordionItem
                    title="2. Get some sol"
                    content={[
                      "Log into your Phantom wallet",
                      "Click Solana",
                      "Click receive",
                      "Copy wallet address",
                      "Log into your Coinbase account, create one if you don't have it",
                      "Send Sol to Phantom wallet",
                    ]}
                    onToggle={(isOpen) => setIsAnyAccordionOpen(isOpen)}
                  />
                  <AccordionItem
                    title="3. Buy KWALA on Raydium"
                    content={[
                      "Go to this link: https://raydium.io/swap/",
                      "Ensure Sol is the 'From' token",
                      "Select Kwala as the 'To' token. You can use this address for that: 9h4Y8t3hybRQH6YvufF7y2V6SyVMwKxLPBkJM4xTwuQ3",
                    ]}
                    onToggle={(isOpen) => setIsAnyAccordionOpen(isOpen)}
                  />
                  <AccordionItem
                    title="4. Swap sol for kwala"
                    content={["Trade it!"]}
                    onToggle={(isOpen) => setIsAnyAccordionOpen(isOpen)}
                  />
                  {/* Add more AccordionItems as needed */}
                </div>
              </div>

              <div className=" md:ml-40 z-[0]">
                <div className=" sm:mt-20 md:mt-8 md:h-1/3 md:w-2/3 lg:h-80 lg:mt-0">
                  <div className="text-white text-4xl font-['Faster_One'] mb-4 lg:text-5xl text-center">
                    $Kwala
                  </div>
                  <img
                    src={logo}
                    alt="Kwala"
                    className="w-1/4 h-1/4 mx-auto sm:h-auto md:h-full md:w-full rounded-full lg:rounded-3xl md:min-w-56"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    </section>
  );
}
